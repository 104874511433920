import React, { useContext, useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import CheckoutContext from "../contexts/CheckoutContext";
import axios from "axios";
import LoadingSpinner from "../components/LoadingSpinner";

const PurchaseSuccess = () => {

    const navigate = useNavigate();
    const { payment_id } = useParams();

    const [purchase, setPurchase] = useState();
    const [loading, setLoading] = useState(true);
    const [applyDiscount, setApplyDiscount] = useState(false);
    const [total, setTotal] = useState(null);
    const [totalWithDiscount, setTotalWithDiscount] = useState();

    //useEffect to get the purchase, including products, address and instance
    useEffect(() => {
        async function getPurchase() {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}purchase/payment-purchase/${payment_id}`);
                setPurchase(response.data);

                //if total quantity is >= Kilos number discount, then change applyDiscount to true
                let total_quantity = 0;
                for (let i = 0; i < response.data.purchasebags.length; i++) {
                    total_quantity += response.data.purchasebags[i].quantity;
                }
                if (total_quantity >= process.env.REACT_APP_KILOS_NUMBER_DISCOUNT) {
                    setApplyDiscount(true);
                } else {
                    setApplyDiscount(false);
                }
                calculateTotalPrices(response.data);

                setLoading(false);
            } catch (error) {
                console.log(error);
            }
        }
        getPurchase();
    }, []);

    function calculateTotalPrices (purchase) {
        let total = 0;
        for (let i = 0; i < purchase.purchasebags.length; i++) {
            total += purchase.purchasebags[i].product.currentPrice * purchase.purchasebags[i].quantity;
        }
        setTotal(total);
        setTotalWithDiscount(purchase.totalAmount);
    }

    if (loading) {
        return (
            <div className="text-center">
                <LoadingSpinner/>
            </div>
        );
    } else {
        return (
            <div className="mx-auto p-4 max-w-md sm:max-w-lg md:max-w-xl lg:max-w-2xl xl:max-w-3xl">
                <div className="bg-white shadow-md rounded-lg p-6">
                    <div className="mb-8 text-center">
                        <h1 className="text-2xl font-bold text-green-600 mb-2">¡Gracias por tu compra, {purchase.buyerName}!</h1>
                        <p className="text-gray-600">Detalle de tu pedido:</p>
                    </div>

                    <div className="mb-4">
                        {purchase.purchasebags.map((purchasebag) => (
                            <div key={purchasebag.product.id} className="flex items-center justify-between mb-2">
                                <p>
                                    {purchasebag.quantity} {purchasebag.quantity == 1 ? 'caja' : 'cajas'} de {purchasebag.product.name}
                                </p>
                                <p className="text-green-600">${(purchasebag.product.currentPrice * purchasebag.quantity).toLocaleString().replace(/,/g, '.')}</p>
                            </div>
                        ))}
                    </div>

                    <div className="mb-4">
                        <p>
                            Día de despacho: {purchase.instance.dayName} {purchase.instance.dayNumber} de {purchase.instance.monthName}.
                        </p>
                        <p>
                            Dirección de entrega: {purchase.address.streetName} {purchase.address.streetNumber}{purchase.address.houseNumber ? ` (Casa ${purchase.address.houseNumber})` : ""}{purchase.address.houseNumber ? ` (Dpto ${purchase.address.departmentNumber})` : ""}, {purchase.address.commune}
                        </p>
                    </div>

                    <div className="border-t-2 border-gray-300 pt-4">
                        {applyDiscount ? (
                            <div>
                                <p className="text-lg font-semibold text-green-600 line-through">
                                    Total: ${total?.toLocaleString().replace(/,/g, '.')}
                                </p>
                                <p className="text-lg font-semibold text-green-600">
                                    Total Pagado: ${totalWithDiscount?.toLocaleString().replace(/,/g, '.')}
                                </p>
                            </div>
                        ) : (
                            <p className="text-lg font-semibold text-green-600">
                                Total Pagado: ${total?.toLocaleString().replace(/,/g, '.')}
                            </p>
                        )}
                    </div>

                    <div className="text-center mt-6">
                        <button   
                            className="bg-green-600 text-white rounded-full px-6 py-3 hover:bg-green-500 transition-all duration-300"
                            onClick={() => navigate("/")}
                        >
                            Volver al inicio
                        </button>
                    </div>
                </div>
            </div>
        );
    }
}

export default PurchaseSuccess;