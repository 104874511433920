import React, { useEffect, useState } from "react";


function ProductsResume (props) {

    const [applyDiscount, setApplyDiscount] = useState(false);
    const [total, setTotal] = useState(null);
    const [totalWithDiscount, setTotalWithDiscount] = useState();

    useEffect(() => {
        //if total quantity is >= 4, then change applyDiscount to true
        let total_quantity = 0;
        for (let i = 0; i < props.items.length; i++) {
            total_quantity += props.items[i].quantity;
        }
        if (total_quantity >= process.env.REACT_APP_KILOS_NUMBER_DISCOUNT) {
            setApplyDiscount(true);
        } else {
            setApplyDiscount(false);
        }
        calculateTotalPrices();
    }, [props.items]);

    function calculateTotalPrices () {
        let total = 0;
        for (let i = 0; i < props.items.length; i++) {
            total += props.items[i].total;
        }
        setTotal(total);
        const MultipleToDiscountAsNumber = Number((1 - process.env.REACT_APP_KILOS_NUMBER_DISCOUNT_PERCENTAGE).toFixed(2));
        setTotalWithDiscount(total * MultipleToDiscountAsNumber);
    }

    return(
        <div className="bg-slate-50 py-5">
            <div className="font-bold text-2xl px-4 text-gray-600 mb-3 text-center">
                <p>Ítems en el Carrito</p>
            </div>
            <table className="w-full text-center">
                <thead className="text-gray-600 uppercase">
                    <tr>
                        <th>Producto</th>
                        <th>Cantidad</th>
                        <th>Subtotal</th>
                    </tr>
                </thead>
                <tbody>
                    {props.items.map((item) =>
                        <tr key={item.product.id} className="text-gray-500">
                            <td>{item.product.name}</td>
                            <td>{item.quantity} {item.quantity == 1 ? 'Caja' : 'Cajas'}</td>
                            <td>${item.total.toLocaleString().replace(/,/g, '.')}</td>
                        </tr>
                    )}
                </tbody>
            </table>
            <div className="font-bold text-gray-500 text-center mt-3 uppercase">
                { applyDiscount ? (
                    <div>
                        <div>
                            <p className="line-through">Total: ${total.toLocaleString().replace(/,/g, '.')}</p>
                        </div>
                        <div className="text-gray-600">
                            <p>Total a Pagar: ${totalWithDiscount.toLocaleString().replace(/,/g, '.')}</p>
                        </div>
                    </div>
                    ) : total ? (
                    <div className="text-gray-600 text-bold">
                        <p>Total a Pagar: ${total.toLocaleString().replace(/,/g, '.')}</p>
                    </div>
                    ) : (
                        null
                    )
                }
            </div>
        </div>
    )
}

export default ProductsResume;